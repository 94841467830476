.mainContainer {
    text-align: -webkit-center;
}

.phoneContainer {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 44rem;
    width: 22rem;
}

.iosSwitchEnable {
    border-radius: 0.625rem;
    border: 0.063rem;
    border-style: solid;
    border-color: var(--bs-success);
    color: white;
    background-color: var(--bs-success);
}

.iosSwitchDisabled {
    border-radius: 0.625rem;
    border: 0px;
    background-color: #e6e5e5;
}

.androidSwitchEnable {
    border-radius: 0.625rem;
    border: 0.063rem;
    border-style: solid;
    border-color: var(--bs-success);
    color: white;
    background-color: var(--bs-success);
}

.androidSwitchDisable {
    border-radius: 0.625rem;
    border: 0px;
    background-color: #e6e5e5;
}

.mainSwitch {
    margin-bottom: 2rem;
}

.colSwitch {
    border-radius: 1rem;
    background-color: #e6e5e5;
    padding: 0px;
}