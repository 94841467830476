.area{
    background-color: #ffffff;
    border-radius: 2rem;
}

.content{
    margin: 1.7rem;
}

.font {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    font-style: normal;
}

.title {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1.45rem;
    font-weight: 600;
    font-style: normal;
    margin-left: 1.2rem;
    color: #181c32;
}

.subtitle {
    font-family: Inter, Helvetica, sans-serif;
    font-size: .95rem;
    font-weight: 500;
    font-style: normal;
    margin-left: 1.2rem;
    color: #a1a5b7;
}

.search{
    margin: 0px;
    padding: 0px;
}

.filterTitle {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1.45rem;
    font-weight: 600;
    font-style: normal;
    margin-left: 3rem;
    color: #181c32;
    width: auto;
}

.filterBar{
    border-bottom: inset;
    width: 99%;
    margin: auto !important;
}

.filterBarCol{
    padding-left: 0px;
    margin-left: 0px;
}

.filterBarButtomCol{
    text-align: right;
    padding: 0px;
    margin-bottom: 0.7rem;
}

.filterBarButtomColInline{
    text-align-last: right;
    width: fit-content;
    margin-top: auto;
}

.filterBarButtomColInlineButton{
    margin-top: 8%;
}

.filterBarRow{
    margin-bottom: 0.7rem;
}

