.title {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1.45rem;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 1rem;
    color: #181c32;
}

.container
{
    margin-top: 1.2rem;
    margin-left: 1.2rem;
}

.textbox{
    background-color: white !important;
    height: 3rem !important; 
    border-color: #CCCCCC !important;
    width: 100% !important;
}

.richTextbox{
    background-color: white !important;
    border-color: #CCCCCC !important;
    width: 100% !important;
}

.saveButton{
    margin-top: 2rem;
    height: 3rem !important;
}

.inner_addon {
    position: relative;
  }
  
  /* style icon */
  .inner_addon .glyphicon {
    position: absolute;
    padding: 8px;
    margin-top: 0.4rem;
    pointer-events: none;
  }

  /* align icon */
  .left_addon .glyphicon {
    left: 0px;
  }
  .right_addon .glyphicon {
    right: 0px;
  }
  
  /* add padding  */
  .left_addon input {
  padding-left: 3rem;
  }
  .right_addon input {
    padding-right: 30px;
  }

  .ruleContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* Left Column */
.ruleLeftColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Right Column */
.ruleRightColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.donutChart {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}