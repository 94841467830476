.titleBar {
    margin-top: 3rem;
    width: 100%;
    height: 2rem;
}

.veracityLogo {
    width: 1.5rem;
    margin-left: 1.5rem;
}

.FontAwesomeIcon {
    font-size: 1.5rem;
}

.whiteFont {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: light;
    margin-bottom: 1rem;
    color: white;
    margin-top: 0.5rem;
}

.contentProfile {
    width: 22rem;
    margin-left: 0.75rem;
}

.sliderArea {
    width: 86.4%;
    height: 12.5rem;
    background-color: white;
    margin-left: 1.5rem;
}

.footer {
    width: 20.5rem;
    margin-left: 0.75rem;
    border-radius: 2rem;
}

.sliderBorder {
    background-color: black;
    width: 20rem;
    height: 10rem;
    margin: auto;
    border-radius: 0.7rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sliderTitle {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    font-style: normal;
    color: white;
    margin: 0px;
    margin-top: 1rem !important;
    padding: 0px;
    height: 1.1rem;
}

.sliderContent {
    font-family: Inter,Helvetica, sans-serif;
    font-size: 0.7rem;
    font-weight: 300;
    font-style: light;
    color: white;
    padding: 0px;
    margin: 0px;
    height: 4rem;
    margin-top: 0.3rem !important;
    text-align: left;
}

.sliderButton {
    width: 6rem;
    height: 2rem;
    background-color: black;
    color: white;
    border-radius: 1rem;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    font-style: light;
}

.videoPlayIcon {
    margin: auto
}