.card {
    width: 300px;
    height: 175px;
    background-color: #FBE38EB2;
    /* Light yellow background */
    border-radius: 10px;
    padding-top: 15px;
    text-align: center;
    // font-family: 'Gentona';
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
        /* Needed for positioning elements inside */
        


}

.cardTitle {
    font-weight: bold;
    margin-bottom: 10px;
    // border-bottom: 0.5px solid #CFCFCF;
    /* Thin line below the title */
    padding-bottom: 10px;
    /* Space between the text and the line */
    font-family: 'Gentona';
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;

    

    
}

.cardContent {
    text-align: left;
    margin-bottom: 15px;
    padding-left: 10px;
    font-family: Gentona;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}

.buttonGroup {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.leftButtonGroup {
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 10px;
}

.buttonGroup button {
    background-color: white;
    border: none;
    border-radius: 5px;
    width: 31px;
    height: 36px;
    cursor: pointer;
    font-size: 20px;
}

.percentageDisplay {
    border: 0.5px solid #00000080;
    border-radius: 5px;
    padding: 5px;
    width: 62px;
    text-align: center;
    height: 35px;
    font-family: Gentona;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    padding-top: 8px;

}


.container {
    width: 300px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header {
    background-color: #31638A; /* Dark blue background */
    color: white;
    text-align: center;
    padding: 15px 0;
    font-weight: bold;
}

.textBoxFont {
    width: 65px;
    font-family: Gentona;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
}