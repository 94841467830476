.container {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    height: 91%;
}

.blankDiv {
    width: auto;
    height: 1.3vh;
    margin: auto;
}

.mainDiv {
    width: auto;
    height: 100%;
    margin: auto;
    background-color: white;
    margin-top: 2rem;
    border-bottom-left-radius: 1.7rem;
    border-bottom-right-radius: 1.7rem;
    overflow: hidden;
}

.sdkHeader {
    width: 100%;
}

.sdkCustomRow {
    width: 100%;
    display: flex;
}

.sdkCloseButtonDiv {
    height: 2.2rem;
    width: 2.2rem;
    background-color: #F5F5F5;
    border-radius: 10rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;
}

.sdkCloseButton {
    margin: auto;
    font-size: 0.7rem;
}

.sdkContent {
    width: auto;
    height: 10rem;
    background-color: #F5F5F5;
    margin-top: 3.5rem;
    display: flex;
    align-items: center;
}

.sdkVideoContentDiv {
    width: 50%;
    margin: auto;
}

.sdkVideoContent {
    width: 100%;
    height: 100%;
}

.sdkImageContent {
    margin: auto;
    height: 100%;
}

.sdkFooter {
    margin-top: 2rem;
}

.sdkContentTitle {
    width: 80%;
    margin: auto;
    font-size: large;
    font-weight: 900;
}

.sdkContentDiv {
    margin: auto;
    margin-top: 1.4rem !important;
    width: 90%;
    font-size: x-small;
}

.sdkCustomRowWithMargin {
    margin-top: 8rem;
}

.sdkDotBlack {
    width: 1rem;
    height: 1rem;
    background-color: #000000;
    border-radius: 2rem;
}

.sdkDotGray {
    width: 1rem;
    height: 1rem;
    background-color: #BEBEBE;
    border-radius: 2rem;
}