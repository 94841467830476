.maps {
  height: 100%;
  width: 100%;
  position: relative;
}

div.maps div:first-child {
  position: absolute !important;
}

.pin {
  width: 3rem;
  height: 3rem;
}

.pinText{
  min-width: 7rem;
  font-family: Gentona, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  color: red;
}