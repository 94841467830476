.container{
    height: 73vh;
    max-height: 73vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.title {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1.45rem;
    font-weight: 600;
    font-style: normal;
    color: #181c32;
}