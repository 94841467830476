.icon {
    position: absolute;
    right: 0;
    margin-right: 3.75rem;
    width: 0.625rem;
}

.header {
    font-family: Inter, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    font-style: normal;
    color: #000000;
}

.button {
    background-color: #ffffff !important;
    box-shadow: 0px 0.063rem 0.125rem rgba(0, 0, 0, 0.1) !important;
    height: inherit;
}

.accBody {
    padding: 1rem 0rem !important;
}

.accordionItem {
    border: none !important;
}

.accordionHeader {
    height: 3.75rem;
    margin-bottom: 1.25rem;
}