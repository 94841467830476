.img {
  max-width: 100%;
  height: 100%;
}

.imgcontainer {
  height: 10.43rem;
  background-color: #f5f6fa;
  border-radius: 0.313rem;
  display: flex;
  text-align: center;
  align-items: center;
}

.desc {
  font-family: Gentona,  sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
}

.icon {
  height: 1.875rem;
  width: 2.375rem;
  font-style: normal;
  font-size: 2rem;
  font-weight: 400;
}

.containerinside {
  height: 3.625rem;
  width: 10.18rem;
  color: #cccccc;
}
