.title {
  font-family: Inter, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  font-style: normal;
  color: #181c32;
}
.value {
  font-family: Inter, Helvetica, sans-serif;
  font-size: 1.15rem;
  font-weight: 400;
  font-style: normal;
  color: #181c32;
}
.centerDiv {
  justify-content: center;
}

.height30vh {
  height: 30vh;
}

.verticalLine {
  border-left: 0.188rem solid #cccccc;
  width: 0.188rem;
}

.customHeaderStyle {
  font-size: 1.1rem !important;
  font-weight: 400;
  font-style: normal;
  color: #000000;
}

.rejectedReasonForPR {
  font-family:  Inter, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.188rem;
  letter-spacing: -0.021rem;
  margin-left: 0.3rem;
}

.rejectedTitleBoldForDetail {
  font-family: Inter, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.188rem;
  letter-spacing: -0.021rem;
}

.accordionStatusRejected {
  margin-left: 69%;
}

.merchantsRegistredDirectorsTableHeaderFont {
  font-family: Inter, Helvetica, sans-serif;
  font-size: 0.975rem !important;
  font-weight: 600 !important;
  line-height: 1.188rem !important;
  letter-spacing: -0.021rem !important;
}

.pendingDocumentVerificationArea {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 0px;
  padding-right: 0px;
  margin-inline-start: -2rem;
}

.pendingSocialMediaFont {
  font-family: Inter, Helvetica, sans-serif;
  font-size: 0.975rem;
  font-weight: 500;
  line-height: 1.188rem;
  letter-spacing: -0.021rem;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 0px;
}

.requiredCheckBox {
  border-color: red;
}

.tabContent{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 35rem;
}

.pendingSocialMediaVerfiedFont {
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 8rem;
}

.treeDotsforSocialMediaUrl {
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 8rem;
}
